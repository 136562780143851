
export default {
  name: "Footer",
  data() {
    return {
      copyright: "Copyright © 2020-2021 蕙芷农业 版权所有",
      address: "沪ICP备2021006767号",
      tips: "扫描左方二维码关注我们",
    };
  },
  mounted() {
    // 创建时
    const hostName = location.hostname;
    // console.log(hostName);
    const hostArr = ['gnfo.net', 'gnso.cn', 'gnqq.net', 'gnwe.net', 'gnmi.net', 'hzizy.cn', 'jfhzzy.com'];
    let hostIndex = -1;
    hostArr.forEach((item, index) => {
      if (hostName.indexOf(item) > -1) {
        hostIndex = index;
      }
    });
    switch (hostIndex) {
      case 0: { (this as any).hostText(hostIndex); break; }
      case 1: { (this as any).hostText(hostIndex); break; }
      case 2: { (this as any).hostText(hostIndex); break; }
      case 3: { (this as any).hostText(hostIndex); break; }
      case 4: { (this as any).hostText(hostIndex); break; }
      case 5: { (this as any).hostText2(3); break; }
      case 6: { (this as any).hostText2(4); break; }
      // default: console.log((this as any).hostText(hostIndex));
    }
    // console.log(hostIndex);
    // if (hostName.indexOf('gnso.cn') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-2";
    // } else if (hostName.indexOf('gnqq.net') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-3";
    // }
  },
  methods: {
    hostText(index:any) {
      (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
      (this as any).address = "沪ICP备2020038115号-" + (index + 1);
    },
    hostText2(index:any) {
      (this as any).copyright = "Copyright © 2020-2021 上海季芬电子商务有限公司 版权所有";
      (this as any).address = "沪ICP备08023779号-" + (index);
    },
  },
};
