<template>
  <div class="root_page">
    <Nav :path="this.$route.path"></Nav>
    <router-view class="page_router_content" />
    <Footer></Footer>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
require("./less/pc/common.less");

@Options({
  components: {
    Nav,
    Footer,
  },
  data() {
    return {};
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
@import url("./less/pc/page.less");

.root_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page_router_content {
  width: 960px;
  background-color: #fff;
}
</style>
