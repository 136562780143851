<template>
  <div id="header_container">
    <div id="header">
      <router-link to="/">
        <img id="logo" alt="logo" src="@/assets/header_logo.png" />
      </router-link>
      <NavItem
        v-for="(item, index) in items"
        :toPath="item.path"
        :title="item.title"
        :key="`item_${index}`"
      />
    </div>
  </div>
  <hr />
</template>

<script>
import NavItem from "./NavItem.vue";

export default {
  name: "Nav",
  components: {
    NavItem,
  },
  props: {
    list: Array,
  },
  methods: {
  },
  data() {
    return {
      moveIndex: 1,
      moveColor: "#666",
      items: [
        {
          path: "/introduce",
          title: "集团介绍",
        },
        {
          path: "/guinong",
          title: "何谓归农",
        },
        {
          path: "/news",
          title: "新闻动态",
        },
        {
          path: "/community",
          title: "社群互动",
        },
        {
          path: "/farming",
          title: "自然农法",
        },
        {
          path: "/recruitment",
          title: "人才招聘",
        },
        {
          path: "/mallApp",
          title: "商城App",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#header_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header {
  height: 89px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 960px;
  background-color: white;
}

img {
  height: 39px;
  width: 65px;
  margin-right: 30px;
}

hr {
  margin: 0;
  width: 100%;
  border: 0;
  border-top: 1px solid #99999966;
}
</style>