<template>
  <div>
    <router-link
      :to="this.$props.toPath"
      :key="this.$props.toPath"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
    >
      <a :style="{ color:this.$route.path.includes(this.$props.toPath) ? '#f19000' :  color }">{{ this.$props.title }}</a>
    </router-link>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  name: "NavItem",
  props: {
    toPath: String,
    title: String,
  },
  data() {
    return {
      popup: false,
      color: "#666666",
    };
  },
  methods: {
    mouseEnter() {
      this.popup = true;
      this.color = "#f19000";
    },
    mouseLeave() {
      this.popup = false;
      this.color = "#666666";
    },
  },
  mounted() {
    // console.log(this.$props.toPath);
    // console.log(this.$route.path);
  },
})
export default class NavItem extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.router-link-active {
  text-decoration: none;
  color: #f19000;
}

a {
  text-decoration: none;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: #666;
  vertical-align: middle;
  text-align: center;

  &.router-link-exact-active {
    color: #f19000;
  }
}
</style>
